<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">세금계산서현황</h2>
      </div>
      <div class="body_section">
        <!-- 거래정보 입력 -->
        <TransactionTradeInfoTbl
          :isCreate="isCreate"
          :itemData="itemData"
          :taxValidation="taxValidation"
          :taxValidationName="taxValidationName"
          :taxInvoiceNo.sync="taxInvoiceNo"
          :taxInvoiceDate.sync="taxInvoiceDate"
          :attachFileList.sync="attachFileList"
        />
        <!--  :taxInvoiceApprovalNum.sync="taxInvoiceApprovalNum" -->

        <!-- 거래처 -->
        <TransactionClientInfoTbl :supplier="supplier" :recipient="recipient" />

        <!-- 품목 -->
        <TableViewWithTitle title="품목">
          <template v-slot:colgroup>
            <col style="width: 50px" />
            <col style="width: 50px" />
            <col />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 150px" />
          </template>
          <template v-slot:header>
            <tr>
              <th>월</th>
              <th>일</th>
              <th>품목</th>
              <th>규격</th>
              <th>수량</th>
              <th>단가</th>
              <th>공급가액</th>
              <th>세액</th>
              <th>비고</th>
            </tr>
          </template>
          <template v-slot:body>
            <template v-if="transactionReportGoodsList.length > 0">
              <TransactionGoodsListLine
                v-for="(item, index) in transactionReportGoodsList"
                :key="index"
                :rowData="item"
                :invoiceDateObj="computedTaxInvoiceDate"
              />
            </template>
            <tr v-else>
              <td colspan="9">등록된 품목이 없습니다.</td>
            </tr>
          </template>
        </TableViewWithTitle>
        <div class="tbl_foot">
          <div class="area_total">
            <div class="row_total">
              <dl>
                <dt>공급가액</dt>
                <dd>
                  {{ itemData.totalSupplyPrice | currency }}
                </dd>
                <dt>세액</dt>
                <dd>
                  {{ itemData.totalTax | currency }}
                </dd>
              </dl>
            </div>
            <div class="row_total">
              <dl>
                <dt>합계</dt>
                <dd>
                  <span class="txt_price">{{ itemData.totalPrice | currency }}</span>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <!-- 처리내역 -->
        <TableViewWithTitle
          v-if="transactionReportAgreeHistory && transactionReportAgreeHistory.length > 0"
          title="처리내역"
        >
          <template v-slot:colgroup>
            <col style="width: 130px" />
            <col style="width: 120px" />
            <col style="width: 80px" />
            <col />
          </template>
          <template v-slot:header>
            <th>등록일자</th>
            <th>등록자</th>
            <th>상태</th>
            <th>사유</th>
          </template>
          <template v-slot:body>
            <TransactionHistoryListLine
              v-for="(history, index) in transactionReportAgreeHistory"
              :key="index"
              :rowData="history"
            />
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button class="btn_fourthly btn_large" type="button" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button class="btn_fourthly btn_large" type="button" @click="onClickPreview">
          거래명세서 미리보기
        </button>
        <button v-if="isCreate" class="btn_primary btn_large" type="button" @click="onClickSubmit">
          저장
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isConfirm"
        alertText="세금계산서등록 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseConfirm"
        @onClickPrimary="onClickSubmitConfirm"
      />
      <TransactionPreviewPopup
        v-if="isPreviewPopup"
        :type="'supplier'"
        :taxInvoiceNo="computedTaxInvoiceNo"
        :itemData="itemData"
        :supplier="supplier"
        :recipient="recipient"
        :invoiceDateObj="computedTaxInvoiceDate"
        :transactionReportGoodsList="transactionReportGoodsList"
        @onClickClose="onClickClosePreview"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Sticky from "@/components/layout/content/Sticky.vue";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TransactionGoodsListLine from "@/components/user/transactionReport/view/TransactionGoodsListLine";
import TransactionClientInfoTbl from "@/components/user/transactionReport/view/TransactionClientInfoTbl";
import TransactionTradeInfoTbl from "@/components/user/transactionReport/view/TransactionTradeInfoTbl";
import TransactionHistoryListLine from "@/components/user/transactionReport/view/TransactionHistoryListLine";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import TransactionPreviewPopup from "@/components/admin/transactionReport/popup/TransactionPreviewPopup";

import ApiService from "@/services/ApiService";
import { stringToDateWithTime, dateToString } from "@/utils/dateUtils";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "TransactionReportView",
  components: {
    TransactionPreviewPopup,
    AlertPopup,
    TransactionTradeInfoTbl,
    TransactionClientInfoTbl,
    TransactionGoodsListLine,
    TransactionHistoryListLine,
    TableViewWithTitle,
    PageWithLayout,
    Sticky,
  },
  data() {
    return {
      taxValidation: "",
      taxValidationName: "",
      transactionReportCid: "",
      taxInvoiceNo: "",
      taxInvoiceDate: "",
      //taxInvoiceApprovalNum: '',
      issue: "",
      agreeYn: false,
      disagreeReason: "",
      projectCode: "",
      projectName: "",
      attachFileList: [],
      itemData: {},

      transactionReportAgreeHistory: [],
      transactionReportGoodsList: [],
      isCreate: false,
      isConfirm: false,
      isPreviewPopup: false,
    };
  },
  computed: {
    computedTaxInvoiceDate() {
      const ret = {
        year: this.taxInvoiceDate ? this.moment(this.taxInvoiceDate).format("YYYY") : null,
        month: this.taxInvoiceDate ? this.moment(this.taxInvoiceDate).format("MM") : null,
        day: this.taxInvoiceDate ? this.moment(this.taxInvoiceDate).format("DD") : null,
      };
      return ret;
    },
    computedTaxInvoiceNo() {
      let ret = "";
      if (this.taxInvoiceNo) {
        const first = this.taxInvoiceNo.substr(0, 8);
        const secend = this.taxInvoiceNo.substr(8, 8);
        const third = this.taxInvoiceNo.substr(16, 8);
        ret = `${first}-${secend}-${third}`;
      } else {
        ret = null;
      }
      return ret;
    },
    supplier() {
      const {
        partnerCorporationNum,
        partnerCompanyName,
        partnerAddress,
        partnerCeo,
        partnerBusiness,
        partnerBusinessType,
      } = this.itemData;
      const obj = {
        corporateNum: partnerCorporationNum,
        ceo: partnerCeo,
        name: partnerCompanyName,
        address: partnerAddress,
        conditions: partnerBusiness,
        type: partnerBusinessType,
      };
      return obj;
    },
    recipient() {
      const { corporateNum, companyName, address, ceo, business, businessType } = this.itemData;
      const obj = {
        corporateNum: corporateNum,
        ceo: ceo,
        name: companyName,
        address: address,
        conditions: business,
        type: businessType,
      };
      return obj;
    },
  },
  created() {
    this.transactionReportCid = this.$route.params.id;
    this.getData();

    // 상태에 따라 작성인지, 상세인지
  },
  methods: {
    async getData() {
      await this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.TRANSACTION}/${this.transactionReportCid}`;
      const result = await ApiService.shared.getData(path);
      await this.$store.dispatch(PROGRESS_END_ACTION);
      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: text,
        });
        return;
      }

      this.itemData = _.cloneDeep(data);
      const {
        taxValidation,
        taxValidationName,
        taxInvoiceNo,
        taxInvoiceDate,
        issue,
        attachFileList,
        projectCode,
        projectName,
        agreeYn,
        transactionReportAgreeHistory,
        transactionReportGoodsList,
      } = this.itemData;
      this.taxValidation = taxValidation;
      this.taxValidationName = taxValidationName;
      this.taxInvoiceNo = taxInvoiceNo || "";
      this.taxInvoiceDate = taxInvoiceDate ? this.moment(taxInvoiceDate).format("YYYY-MM-DD") : "";
      //this.taxInvoiceApprovalNum = this.itemData.taxInvoiceApprovalNum || '';
      this.issue = issue || "";
      this.attachFileList = attachFileList || [];
      this.projectCode = projectCode;
      this.projectName = _.unescape(projectName);
      this.agreeYn = agreeYn;
      this.disagreeReason = "";
      if (!this.itemData.transactionReportAgreeHistory) {
        this.transactionReportAgreeHistory = [];
      } else {
        this.transactionReportAgreeHistory = transactionReportAgreeHistory;
      }
      this.transactionReportGoodsList = _.cloneDeep(transactionReportGoodsList);

      this.isCreate = !agreeYn;
    },
    // 목록 이동
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.TRANSACTIONREPORT_LIST,
        query: this.$route.query,
      });
    },
    // 저장 버튼
    onClickSubmit() {
      if (this.getAlertMessage()) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: this.getAlertMessage(),
        });
        return;
      }
      this.isConfirm = true;
    },
    // 청구 컨펌 > 닫기
    onClickCloseConfirm() {
      this.isConfirm = false;
    },
    // 청구 컨펌 > 확인
    onClickSubmitConfirm() {
      this.submitInvoice();
      // this.isCreate = false;
      // this.isConfirm = false;
    },
    async submitInvoice() {
      const path = `${this.$apiPath.TRANSACTION}/${this.transactionReportCid}`;
      const obj = {
        transactionReportCid: this.transactionReportCid,
        taxInvoiceNo: this.taxInvoiceNo.replace(/\-/g, ""),
        taxInvoiceDate: this.moment(this.taxInvoiceDate, "YYYY-MM-DD").format("YYYYMMDD"),
        attachFileList: this.attachFileList,
      };

      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: text,
        });
        return;
      }

      if (data) {
        window.location.reload();
      }

      //
    },
    // 미리보기
    onClickPreview() {
      this.isPreviewPopup = true;
    },
    // 미리보기 닫기
    onClickClosePreview() {
      this.isPreviewPopup = false;
    },
    getAlertMessage() {
      let alertMessage = "";
      const taxInvoiceNo = this.taxInvoiceNo.replace(/\-/g, "");
      if (taxInvoiceNo.length !== 24) {
        return (alertMessage = "세금계산서번호를 확인해주세요.");
      }
      if (!this.taxInvoiceDate) {
        return (alertMessage = "발행일자를 확인해주세요.");
      }
      // if (this.taxInvoiceApprovalNum.length !== 24) {
      //   return (alertMessage = '승인번호를 확인해주세요.');
      // }
      return null;
    },
  },
};
</script>
<style scoped>
.row_total:after {
  display: block;
  clear: both;
  content: "";
}

.row_total + .row_total {
  margin-top: 8px;
}

.txt_price {
  font-size: 18px;
}
</style>
